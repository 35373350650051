import { config } from "src/config";
import Http from "./api.service";
import LogService from "./log.service";

const AuthService = () => {
    const api = Http(true, config().baseServiceProviderUrl).instance;

    const getUser = async () => {
        return await api.get('/api/user').then((response) => {
            return response.data;
        });
    }

    const logIn = () => {
        window.location.replace(config().baseServiceProviderUrl + '/saml/login');
    }

    const logOut = (userEmailAddress: string) => {
        LogService().postLog({
            action_type: 'LOGOUT',
            log_date: new Date().toISOString(),
            tp_email_id: userEmailAddress
        }).then(() => {
            window.location.replace(config().baseServiceProviderUrl + '/saml/logout');
        });
    }

    return { getUser, logIn, logOut };
}

export default AuthService;
