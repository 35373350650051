import { Grid, CircularProgress } from "@mui/material"

const Loader = () => {
    return (
        <Grid container justifyContent="center">
            <CircularProgress size={100} />
        </Grid>
    )
}

export default Loader;