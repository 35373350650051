import { useState, FC, createContext } from 'react';
import { ErrorContextType, ErrorObject, Props } from 'src/types/index';

export const ErrorContext = createContext<ErrorContextType | null>(null);

const ErrorProvider: FC<Props> = ({ children }) => {
  const [error, setError] = useState<Array<ErrorObject>>([]);

  const setErrorMessage = (error: ErrorObject) => {
    setError((prevState) => [...prevState, error]);
  }

  const deleteError = (id: number) => {
    setError((prevState) => prevState.filter((error) => error.id !== id));
  }

  return <ErrorContext.Provider value={{ error, setErrorMessage, deleteError }}>{children}</ErrorContext.Provider>
}

export default ErrorProvider;
