import { Grid, Button, Container } from '@mui/material';

const SubmitForm = () => {
    return (
        <Container maxWidth="xl" sx={{ p: 2 }}>
            <Grid container spacing={2} justifyContent="right">
                <Button variant="contained" color="success" type="submit">
                    Submit
                </Button>
            </Grid>
        </Container>
    );
};

export default SubmitForm;