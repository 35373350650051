import { FC, forwardRef } from 'react';
import DataTable from '../dataTable/dataTable';
import RemarkForm from '../remarkForm/remarkForm';
import { Patient } from 'src/types/index';
import { Container, } from '@mui/material';

type DataProps = {
    data: Array<Patient>,
    fieldName: string
};


const ProgramFormWrapper = forwardRef<HTMLDivElement, DataProps>(function Form(props, ref,) {
    const { data, fieldName } = props;

    return (
        <Container ref={ref}>
            <DataTable data={data} program_number={fieldName} />
            <RemarkForm name={fieldName} />
        </Container>
    );
});

const ProgramForm: FC<DataProps> = ({ data, fieldName }) => {
    return (<ProgramFormWrapper data={data} fieldName={fieldName} />)
}

export default ProgramForm;