import { useContext } from "react";
import { Navigate } from "react-router-dom";
import { AuthContext } from "src/utils/authContext";
import { ROUTES } from "./routes";

const ProtectedRoute = ({ children }) => {
    const authContext = useContext(AuthContext);

    if (!authContext.isAuthenticated) {
        return <Navigate to={ROUTES.LOGIN} replace />;
    }

    return children;
};

export default ProtectedRoute