import { useState, FC, createContext, useEffect } from 'react';
import { ROUTES } from 'src/routes/routes';
import AuthService from 'src/services/auth.service';
import router from 'src/routes/router';
import { AuthContextType, Props, User } from 'src/types/index';

export const AuthContext = createContext<AuthContextType | null>(null);

const AuthProvider: FC<Props> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [user, setUser] = useState<User | undefined>();


  useEffect(() => {
    authRequest();
  }, []);

  useEffect(() => {
    if (isAuthenticated && router.state.location.pathname === ROUTES.LOGIN) {
      router.navigate(ROUTES.FORM);
    }
  }, [isAuthenticated]);

  const checkAuthentication = async () => {
    const checked = await authRequest()
      .then(() => {
        return isAuthenticated ? true : false
      })
      .catch(() => {
        return false
      })

    return checked;
  }

  const authRequest = async () => {
    await AuthService().getUser()
      .then((response: User) => {
        if (typeof response === 'object') {
          setUser(response);
          sessionStorage.setItem('eva_token', response.token);
          sessionStorage.setItem('eva_session', response.session);
          setIsAuthenticated(true);
        }
        else {
          setIsAuthenticated(false);
        }
      })
  };

  return (<AuthContext.Provider value={{ isAuthenticated, user, checkAuthentication }}>{children}</AuthContext.Provider>);
}

export default AuthProvider;
