import { FC } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Container from '@mui/material/Container';
import { Patient } from 'src/types/index';
import { Headers } from 'src/utils/constant';


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.primary.main,
  },
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.primary.light,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));


type CustomizedTablesProps = {
  data: Array<Patient>,
  program_number: string
};


const CustomizedTables: FC<CustomizedTablesProps> = ({ data, program_number }) => {

  return (
    <Container maxWidth="xl">
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 1000 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              {Headers.map((title, index) => (
                <StyledTableCell align="center" key={title + index}>{title}</StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <StyledTableRow key={index}>
                <StyledTableCell align="center" component="th" scope="row">
                  {program_number}
                </StyledTableCell>
                <StyledTableCell align="center">{row.program}</StyledTableCell>
                <StyledTableCell align="center">{row.program_type}</StyledTableCell>
                <StyledTableCell align="center">{row.products}</StyledTableCell>
                <StyledTableCell align="center">{row.lrn}</StyledTableCell>
                <StyledTableCell align="center">{row.patient_id}</StyledTableCell>
                <StyledTableCell align="center">{row.events_reported}</StyledTableCell>
                <StyledTableCell align="center">{row.country}</StyledTableCell>
                <StyledTableCell align="center">{row.latest_received_date}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container >
  );
}

export default CustomizedTables;
