import Header from "src/components/header/header";
import LoginPage from "src/pages/loginPage";
import MainFormPage from "src/pages/formPage";
import { createBrowserRouter, Navigate, Outlet } from "react-router-dom";
import Footer from "src/components/footer/footer";
import { Grid } from "@mui/material";
import ErrorPage from "src/pages/errorPage";
import { ROUTES } from "./routes";
import ProtectedRoute from "./protectedRoute";

const AppWrapperSx = {
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
}

const AppGridSx = {
  flex: 1,
  minWidth: '100vh',
  minHeight: 'calc(100vh - 204px)'
}


const Layout = () => {
  return (
    <Grid sx={AppWrapperSx}>
      <Header />
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={AppGridSx}
      >
        <Outlet />
      </Grid>
      <Footer />
    </Grid >
  )
}

const router = createBrowserRouter([
  {
    element: (
      <Layout />
    ),
    children: [
      {
        index: true,
        element: <Navigate to={ROUTES.FORM} replace={true} />,
      },
      {
        path: ROUTES.FORM,
        element: (
          <ProtectedRoute>
            <MainFormPage />
          </ProtectedRoute>),
      },
      {
        path: ROUTES.LOGIN,
        element: <LoginPage />,
      },
      {
        path: ROUTES.ERROR,
        element: <ErrorPage />,
      },
      {
        path: '*',
        element: <Navigate to={ROUTES.LOGIN} replace={true} />,
      }
    ],
  },
]);

export default router;