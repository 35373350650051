import { Grid, Typography, Button, Paper, } from "@mui/material";
import { FC } from "react";
import router from 'src/routes/router';
import { ROUTES } from "src/routes/routes";

const noProgramBackgrougSx = {
  justifyContent: 'center',
  padding: '50px',
  display: 'flex',
  flexDirection: 'column',
  minWidth: '100vh'
};

const ErrorPage: FC = () => {

  const redirectToForm = () => {
    router.navigate(ROUTES.FORM);
  }

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Paper
        variant="elevation"
        elevation={2}
        sx={noProgramBackgrougSx}
      >
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center">
          <Typography variant="h1">
            Error
          </Typography>
          <Typography variant="h6" gutterBottom>
            An error occurred. Please try again.
          </Typography>
        </Grid>
        <Grid item>
          <Grid container justifyContent="center">
            <Grid item>
              <Button
                fullWidth
                variant="outlined"
                color="secondary"
                size="medium"
                onClick={redirectToForm}
              >
                Back to Form
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid >
  )
}

export default ErrorPage;