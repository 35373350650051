import { useContext, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Grid } from '@mui/material';
import ProgramForm from '../components/programForm/programForm';
import SubmitButton from '../components/submitForm/submitForm';
import Typography from '@mui/material/Typography';
import { FormProvider, useForm, } from "react-hook-form";
import { Data } from 'src/types/index';
import { useEffect } from 'react';
import FormService from 'src/services/form.service';
import Loader from 'src/components/loader/loader';
import { mapDataToPost } from 'src/utils/helpers';
import NoData from 'src/components/noData/noData';
import { AuthContext } from 'src/utils/authContext';
import LogService from 'src/services/log.service';
import { ErrorContext } from 'src/utils/errorContext';

const Div = styled('div')(({ theme }) => ({
    ...theme.typography.h6,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline',
    padding: theme.spacing(1),
}));

const MainFormPage = () => {
    const [tpData, setTpData] = useState<Data>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const formMethods = useForm();
    const { checkAuthentication, user } = useContext(AuthContext);
    const errorContext = useContext(ErrorContext);

    useEffect(() => {
        const formRequest = () => {
            FormService().getData(user.emailaddress).then((response: Data) => {
                setTpData(response)
                setIsLoading(false);
            }).catch((error) => {
                errorContext.setErrorMessage({ id: Date.now(), message: error.message })
                setIsLoading(false);
            })
        }
        if (isLoading) {
            formRequest();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, isLoading])


    const onSubmit = async () => {
        setIsLoading(true);
        const checkAuth = await checkAuthentication();
        if (!checkAuth) {
            errorContext.setErrorMessage({ id: Date.now(), message: "Your session expired, please log in again" });
            setIsLoading(false);
        } else if (checkAuth && tpData) {
            let data = mapDataToPost(tpData, formMethods.getValues());

            FormService().postData(data)
                .then(() => {
                    LogService().postLog({
                        action_type: 'SUBMIT',
                        log_date: new Date().toISOString(),
                        tp_email_id: user.emailaddress
                    }).catch((error) => {
                        errorContext.setErrorMessage({ id: Date.now(), message: error.message });
                    })
                }).catch((error) => {
                    errorContext.setErrorMessage({ id: Date.now(), message: error.message });
                }).finally(() => {
                    setIsLoading(false);
                });
        }
    }

    if (isLoading) {
        return (<Loader />);
    }

    if (!tpData) {
        return (<NoData />);
    }

    return (
        <Grid>
            <Div>{`${tpData.name_of_treating_physician} Verify the Data Assigned to you for completeness`}</Div>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <Div>CTV Period Start Date:&nbsp;<Typography variant="body1">{tpData.ctv_start_date}</Typography></Div>
                </Grid>
                <Grid item xs={12} sm={6} alignItems="center">
                    <Div>CTV Period End Date:&nbsp;<Typography variant="body1">{tpData.ctv_end_date}</Typography></Div>
                </Grid>
            </Grid>
            <form onSubmit={formMethods.handleSubmit(onSubmit)}>
                <FormProvider {...formMethods}>
                    {tpData.tpPrograms.map((program) => (
                        <ProgramForm
                            {...formMethods.register(program.program_number)}
                            key={program.program_number}
                            fieldName={program.program_number}
                            data={program.patients}
                        />
                    ))}
                    <SubmitButton />
                </FormProvider>
            </form>
        </Grid>
    );
};

export default MainFormPage;