import { FieldValues } from "react-hook-form";
import { Data, DataPost, RemarkForm } from "src/types/index";

export const mapDataToPost = (tpData: Data, formValues: FieldValues) => {
    const tp_programs = Object
        .entries<RemarkForm>(formValues)
        .map(([programNumber, programData]) => ({
            ...programData,
            tp_comments: programData.tp_comments ? programData.tp_comments : '',
            program_number: programNumber,
            date_of_response_received: generateTimeStamp()
        }));
    const payload: DataPost = {
        ctv_end_date: tpData.ctv_end_date,
        ctv_start_date: tpData.ctv_start_date,
        tpEmailId: tpData.tpEmailId,
        tpDetails: tp_programs
    }

    return payload;
}

const generateTimeStamp = () => {
    var d = new Date(),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}