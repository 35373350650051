
import { DataPost } from "src/types/index";
import { config } from "src/config";
import Http from "./api.service";


const FormService = () => {
    const api = Http(false, config().baseBackendUrl).instance;

    const getData = async (emailId: string) => {
        return await api.get(`/datatp/${emailId}`)
            .then((response) => {
                return response.data;
            });
    }

    const postData = async (form: DataPost) => {
        return await api.put('/datatp', form);
    }


    api.interceptors.request.use((config) => {
        config.headers['Authorization'] = `Bearer ${sessionStorage.getItem('eva_token')}`;
        config.headers['Session'] = sessionStorage.getItem('eva_session');
        return config;
    });

    return { getData, postData }
}

export default FormService;
