import { Grid, Typography, Button, Paper, Box, } from "@mui/material";
import { FC, useContext } from "react";
import ExostarLogo from 'src/assets/exostarLogo.png';
import AuthService from "src/services/auth.service";
import RocheLogoBig from 'src/assets/rocheLogoBig.png';
import { AuthContext } from "src/utils/authContext";

const noDataBackgrougSx = {
  justifyContent: 'center',
  padding: '50px',
  display: 'flex',
  flexDirection: 'column',
  minWidth: '100vh'
};

const rocheLogoSx = {
  height: 128,
  width: 256,
  placeSelf: 'center'
}

const NoData: FC = () => {
  const { user } = useContext(AuthContext);

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Paper
        variant="elevation"
        elevation={2}
        sx={noDataBackgrougSx}
      >
        <Box
          component="img"
          sx={rocheLogoSx}
          alt="Roche Logo"
          src={RocheLogoBig}
          py={2}
        />
        <Grid item>
          <Typography variant="h4" align="center" gutterBottom>
            No active programs
          </Typography>
        </Grid>
        <Grid item>
          <Grid container justifyContent="center">
            <Grid item>
              <Button
                fullWidth
                variant="outlined"
                color="secondary"
                endIcon={<Box
                  component="img"
                  src={ExostarLogo}
                  alt="ExostarLogo"
                />}
                size="medium"
                onClick={() => AuthService().logOut(user.emailaddress)}
              >
                Logout from
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid >
  )
}

export default NoData;