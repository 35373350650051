import * as React from 'react';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { ErrorContext } from 'src/utils/errorContext';
import { ErrorObject } from 'src/types/index';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref,) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SnackBarAlert = () => {
    const context = React.useContext(ErrorContext)
    const [error, setError] = React.useState<Array<ErrorObject>>(null)

    React.useEffect(() => {
        if (context) {
            setError(context.error)
        }
    }, [context])

    const handleClose = (id: number) => {
        context.deleteError(id)
    }

    if (!context && !error) {
        return null;
    }

    return (
        <Stack spacing={2} sx={{ width: '100%' }}>
            {error && error.map((e) =>
                <Snackbar
                    key={e.id}
                    open={!!e.message}
                    autoHideDuration={6000}
                    onClose={() => handleClose(e.id)}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                    <Alert severity="error" sx={{ width: '100%' }}>
                        {e.message}
                    </Alert>
                </Snackbar>
            )}
        </Stack >
    );
};

export default SnackBarAlert;