import { ThemeProvider, createTheme } from '@mui/material/styles';
import { RouterProvider } from 'react-router-dom';
import router from 'src/routes/router';
import SnackBarAlert from './components/snackbar/snackbar';
import AuthProvider from './utils/authContext';
import ErrorProvider from './utils/errorContext';

const theme = createTheme({
  palette: {
    primary: {
      light: '#E8EBF5',
      main: '#CDD4EA',
      dark: '#4472C4'
    },
  },
});


const App = () => {

  return (
    <div>
      <ThemeProvider theme={theme}>
        <ErrorProvider>
          <AuthProvider>
            <RouterProvider router={router} />
            <SnackBarAlert />
          </AuthProvider>
        </ErrorProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
