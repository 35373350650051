import { ConfigType } from "src/types";

const prodConfig = {
    baseBackendUrl: 'https://evaportal-api.roche.com/api/v1',
    baseServiceProviderUrl: 'https://evaportal-sp.roche.com'
}

const devConfig = {
    baseBackendUrl: 'https://evaportal-api-test.roche.com/api/v1',
    baseServiceProviderUrl: 'https://evaportal-sp-test.roche.com'
}

export const config = (): ConfigType => {
    let envConfig = {} as ConfigType

    switch (process.env.REACT_APP_DEPLOY_ENV || 'local') {
        case 'local' || 'test':
            envConfig = devConfig;
            break;
        case 'prod':
            envConfig = prodConfig;
            break;
        default:
            envConfig = devConfig;
            break;
    }

    return envConfig;
}