import axios, { AxiosError, AxiosInstance } from 'axios';
import router from 'src/routes/router';
import { ROUTES } from 'src/routes/routes';


const Http = (withCredentials: boolean, url?: string,) => {
  const instance: AxiosInstance = axios.create({ baseURL: url, withCredentials: withCredentials });

  instance.interceptors.response.use(
    (config) => {
      return config;
    },
    (error: AxiosError) => {
      if (!error.response) {
        router.navigate(ROUTES.ERROR)
        return
      }

      const { status } = error.response!;

      switch (status) {
        case 401:
          router.navigate(ROUTES.LOGIN);
          break;

        default:
          router.navigate(ROUTES.ERROR)
          break;
      }
      return Promise.reject(error);
    }
  );

  return { instance }
}

export default Http;
