import { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { FormControl, Select, MenuItem, TextField, Container, Typography, FormHelperText } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useFormContext, Controller } from 'react-hook-form';

const StyledSelect = (styled(Select)(({ theme }) => ({
    "&.MuiOutlinedInput-root": {
        "& fieldset": {
            borderColor: theme.palette.primary.dark,
        },
        "&:hover fieldset": {
            borderColor: theme.palette.primary.dark,
        },
        "&.Mui-focused fieldset": {
            borderColor: theme.palette.primary.dark,
        }
    }
})) as unknown) as typeof Select;


const StyledTextField = (styled(TextField)(({ theme }) => ({
    '& label.Mui-focused': {
        color: theme.palette.primary.dark,
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: theme.palette.primary.dark,
        },
        '&:hover fieldset': {
            borderColor: theme.palette.primary.dark,
        },
        '&.Mui-focused fieldset': {
            borderColor: theme.palette.primary.dark,
        }
    }
})) as unknown) as typeof TextField;


type RemarksPropType = {
    name: string
};

const RemarkAnswares = {
    YES: 'Yes',
    NO: 'No'
};

const RemarkForm = ({ name }: RemarksPropType) => {
    const { control, watch } = useFormContext()
    const [shouldRenderTextField, setShouldRenderTextField] = useState<boolean>(false)
    const watchRemark = watch(name + '.is_line_listing_correct_and_complete');

    useEffect(() => {
        if (typeof watchRemark !== "undefined" && watchRemark === RemarkAnswares.NO) {
            setShouldRenderTextField(true);
        } else {
            setShouldRenderTextField(false);
        }

    }, [watchRemark]);

    return (
        <Container maxWidth="xl" sx={{ p: 2 }}>
            <Grid container spacing={2} alignItems="center" wrap="wrap" >
                <Grid item xs={12} md={4} textAlign="left">
                    <Typography variant="button">Line listing correct and complete with all the safety reports sent to Roche?</Typography>
                </Grid>
                <Grid item xs={12} md={2}>
                    <Controller
                        control={control}
                        name={name + '.is_line_listing_correct_and_complete'}

                        rules={{ required: "This field is required.", pattern: /^(Yes|No)$/ }}
                        render={({
                            field: { onChange, value },
                            fieldState: { error },
                        }) => (
                            <FormControl size="small" fullWidth >
                                <StyledSelect
                                    value={String(value) || ''}
                                    onChange={onChange}
                                    error={!!error}
                                >
                                    <MenuItem value={RemarkAnswares.YES}>{RemarkAnswares.YES}</MenuItem>
                                    <MenuItem value={RemarkAnswares.NO}>{RemarkAnswares.NO}</MenuItem>
                                </StyledSelect>
                                {!!error && (
                                    <FormHelperText error>{error.message}</FormHelperText>
                                )}
                            </FormControl>
                        )}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    {shouldRenderTextField && (
                        <Controller
                            control={control}
                            name={name + '.tp_comments'}
                            rules={{ required: "This field is required." }}
                            render={({
                                field: { onChange, value },
                                fieldState: { error },
                            }) => (
                                <FormControl fullWidth>
                                    <StyledTextField
                                        id="tp_comments"
                                        label="Description"
                                        size="small"
                                        value={value}
                                        multiline
                                        rows={4}
                                        onChange={onChange}
                                        error={!!error}
                                        helperText={error?.message}
                                    />
                                </FormControl>
                            )}
                        />
                    )}
                </Grid>
            </Grid>
        </Container >
    );
}

export default RemarkForm;
