import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      F. Hoffmann-La Roche Ltd{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const Footer = () => {

  return (
    <Box component="footer">
      <Container maxWidth="lg">
        <Typography variant="body2" color="text.secondary">
          This portal contains secret, confidential, and/or proprietary information owned by F. Hoffmann-La Roche Ltd (hereafter “Roche”, “we”, us”), we take data privacy seriously and treat all your personal data in accordance with Roche General Privacy Policy and applicable privacy and data protection laws, including the Swiss Federal Data Protection Act, the European Union General Data Protection Regulation (GDPR), CCPA (California Consumer Privacy Act) and other applicable local laws that regulate the storage, processing, access, and transfer of personal data. For more details please visit: <a href="https://www.roche.com/privacy-policy" target="_blank" rel="noreferrer">https://www.roche.com/privacy-policy</a> and/or <a href="https://www.roche.com/us-privacy-policy" target="_blank" rel="noreferrer">https://www.roche.com/us-privacy-policy</a> .
        </Typography>
        <Copyright />
      </Container>
    </Box>
  );
}

export default Footer;