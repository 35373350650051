import { Grid, Typography, Button, Paper, Box, } from "@mui/material";
import { FC } from "react";
import ExostarLogo from 'src/assets/exostarLogo.png';
import AuthService from "src/services/auth.service";
import RocheLogoBig from 'src/assets/rocheLogoBig.png';

const loginBackgroundSx = {
  justifyContent: 'center',
  padding: '50px',
  display: 'flex',
  flexDirection: 'column',
  width: '100vh'
};

const rocheLogoSx = {
  height: 128,
  width: 256,
  placeSelf: 'center'
}

const LoginPage: FC = () => {

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
    >
      <Paper
        variant="elevation"
        elevation={2}
        sx={loginBackgroundSx}
      >
        <Box
          component="img"
          sx={rocheLogoSx}
          src={RocheLogoBig}
          alt="RocheLogo"
          py={2}
        />
        <Grid item>
          <Typography variant="body2" align="center" gutterBottom>
            I acknowledge that I am bound by confidentiality obligations imposed through my employment or contractual agreement
            with Roche in connection with my access to confidential information, including Event Verification Automated (EVA) and its contents. By
            entering Event Verification Automated (EVA), I confirm that I understand that my activities within Event Verification Automated (EVA) may be monitored consistent
            with local law, and all contents and passwords are confidential information, and that unauthorized disclosure or use of
            such confidential information may result in disciplinary action including termination of my employment or services and/or
            legal action based on local law.
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h5" align="center" gutterBottom>
            Please log in to continue.
          </Typography>
        </Grid>
        <Grid item>
          <Grid container justifyContent="center">
            <Grid item xs={4}>
              <Button
                fullWidth
                variant="outlined"
                color="secondary"
                endIcon={<Box
                  component="img"
                  src={ExostarLogo}
                  alt="ExostarLogo"
                />}
                size="medium"
                onClick={() => AuthService().logIn()}
              >
                Login with
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid >
  )
}

export default LoginPage;