import { Fragment, useContext } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import RocheLogo from 'src/assets/rocheLogo.png';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box/Box';
import { AuthContext } from 'src/utils/authContext';
import { IconButton } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import AuthService from 'src/services/auth.service';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  color: theme.palette.common.black,
}));


const Header = () => {
  const { user } = useContext(AuthContext);

  const onClick = () => {
    AuthService().logOut(user.emailaddress);
  }

  return (
    <Fragment>
      <StyledAppBar position="sticky">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                flexGrow: 1,
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none'
              }}
            >
              EVA BOT
            </Typography>
            <Typography
              variant="h5"
              noWrap
              component="a"
              href=""
              sx={{
                mr: 2,
                display: { xs: 'flex', md: 'none' },
                flexGrow: 1,
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none'
              }}
            >
              EVA BOT
            </Typography>
            {user
              ? (
                <Fragment>
                  <Typography variant="subtitle1">
                    Welcome, {user?.firstName + ' ' + user?.lastname}
                  </Typography>
                  <IconButton size="large" aria-label="Logout" onClick={onClick}>
                    <LogoutIcon />
                  </IconButton>
                </Fragment>
              )
              : null
            }
            <Box component="img" sx={{ color: '#fff', marginLeft: 3 }} src={RocheLogo} alt="RocheLogo" />
          </Toolbar>
        </Container>
      </StyledAppBar>
    </Fragment>
  );
}

export default Header;