import { LogData } from "src/types/index";
import { config } from "src/config";
import Http from "./api.service";


const LogService = () => {
    const api = Http(false, config().baseBackendUrl).instance;

    const postLog = async (form: LogData) => {
        return (await api.post('/logs', form));
    }

    api.interceptors.request.use((config) => {
        config.headers['Authorization'] = `Bearer ${sessionStorage.getItem('eva_token')}`;
        config.headers['Session'] = sessionStorage.getItem('eva_session');
        return config;
    });

    return { postLog }
}

export default LogService;
